let baseUrl = process.env.VUE_APP_SVC_BASE_URL + "/api/"
let authUrl = process.env.VUE_APP_SVC_BASE_URL + "/auth/start"

if (process.env.NODE_ENV === "production") {

} else {

    baseUrl = "http://localhost:9000/svc/api/";
    authUrl = "http://localhost:9000/svc/auth/start";
}

export const apiHost = baseUrl;
export const authStart = authUrl;
