<template>
  <v-menu offset-y v-model="showDropDown" :z-index="zIndex">
    <template v-slot:activator="{}">
      <v-text-field prepend-inner-icon="mdi-magnify" outlined clearable :placeholder="placeholder" :dark="!themeDark"
        dense hide-details @click="doSearch" v-model="searchText"></v-text-field>
    </template>
    <v-list v-if="showDropDown" style="cursor: pointer; max-height: 75vh; overflow-y: auto" class="py-0">
      <template v-for="(item, index) in foundItems">
        <v-list-item @click="itemSelected(item)" :key="index" three-line >
          <v-list-item-avatar>
            <v-icon v-if="item.type === 'job'">mdi-calendar</v-icon>
            <v-icon v-else-if="item.type === 'customer'">mdi-home-city-outline</v-icon>
            <v-icon v-else-if="item.type === 'project'">mdi-folder-multiple-outline</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ item.line1 }}</v-list-item-title>
            <v-list-item-subtitle v-if="item.line2">{{
              item.line2
            }}</v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.line3">{{
              item.line3
            }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon v-if="navigateOnClick">
            <v-icon color="blue">mdi-open-in-app</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-divider :key="item.id + 'h'" v-if="index < foundItems.length - 1"></v-divider>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { readJobs } from "../services/job.js";
import { readCustomers } from "../services/customer.js";
import { readAllItems } from '../services/systemList.js';
import { readProjects } from '../services/project.js'
const brand = import("@/brands/" + process.env.VUE_APP_BRAND + '/theme')

export default {
  name: "GlobalSearch",
  props: {
    onlyCustomers: {
      type: Boolean,
      required: false,
      default: false
    },
    navigateOnClick: {
      type: Boolean,
      required: false,
      default: false
    },
    themeDark: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Search'
    },
    'z-index': {
      type: Number,
      required: false,
      default: 1000
    }
  },
  data: () => ({
    searchText: "",
    foundItems: [],
    customerTypes: [],
    timeout: null,
    showDropDown: false,
    continueSearch: false,
    showProjectTab: false,
  }),
  watch: {
    searchText: function () {
      clearTimeout(this.timeout);
      if (this.continueSearch) {
        this.timeout = setTimeout(
          function () {
            this.doSearch().then(() => { });
          }.bind(this),
          1000
        );
      }
    },
  },
  methods: {
    async init() {
      let brandObj = await brand;
      this.showProjectTab = brandObj.default.projectTab;
      this.customerTypes = await readAllItems('customer_types');
    },
    getCustomerType(typeId) {
      let type = this.customerTypes.find(t => t.id === typeId);

      if (type) return type.name;
      else return '';
    },
    itemSelected: function (item) {
      if (this.navigateOnClick) {
        if (item.type === "job")
          this.$router.push({ name: "Job", params: { jobid: item.id } }).catch((err) => { console.error(err) });
        
        else if (item.type === "customer") {
          this.$router.push({
            name: "EditCustomer",
            params: { id: item.id },
          }).catch((err) => { console.error(err) });;
        }
          
        else if (item.type === 'project'){
          this.$router.push({
            name: "EditProject",
            params: { projectid: item.id }
          }).catch((err) => {console.error(err)});
        }
      }
      else {
        this.searchText = item.line1;
        this.continueSearch = false;
        this.$emit('selectedId', item.id);
      }
    },
    doSearch: async function () {
      this.continueSearch = true;
      if (this.searchText && this.searchText.trim() != "") {
        var items = [];

        if (!this.onlyCustomers) {
          var jobs = await readJobs({ query: this.searchText });
          jobs = jobs.results;

          items = jobs.map((i) => ({
            id: i._id,
            type: "job",
            line1: i.description,
            line2: i.location,
            line3: "#" + i.jobNumber,
          }));

          if (this.showProjectTab) {
            let projects = await readProjects({ query: this.searchText });
            projects = projects.results;

            let projectItems = projects.map(p => ({
              id: p._id,
              type: "project",
              line1: p.description,
              line2: p.location,
              line3: "#" + p.projectNumber
            }));

            items = items.concat(projectItems);
          }          
        }

        var customers = await readCustomers({ query: this.searchText });

        var customerItems = customers.map((c) => ({
          id: c._id,
          type: "customer",
          line1: c.name,
          line2: c.address,
          line3: this.getCustomerType(c.type),
        }));

        items = items
          .concat(customerItems)
          .sort((i, j) => (i.line1 < j.line1 ? -1 : 1));

        this.foundItems = items;
        this.showDropDown = items.length > 0;
      } else {
        this.foundItems = [];
        this.showDropDown = false;
      }
    },
  },
  created() {
    this.init();
  }
};
</script>
