import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";

Vue.use(Router);

let router = new Router({
  mode: "history",
  //base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        anon: true
      }
    },
    {
      path: "/auth/loggedOut",
      name: "loggedOut",
      component: Home,
      meta: {
        anon: true
      }
    },
    {
      path: "/suppliers",
      name: "suppliers",
      meta: {
        roles: ["JobManager.Manager"]
      },
      component: () => import("./views/Suppliers.vue")
    },
    {
      path: "/tools",
      name: "tools",
      meta: {
        roles: ["JobManager.Manager"]
      },
      component: () => import("./views/Tools.vue")
    },
    {
      path: "/jobInfoManagement",
      name: "jobInfoManagement",
      meta: {
        roles: ["JobManager.Administrator"]
      },
      component: () => import("./views/JobInfoManagement.vue")
    },
    {
      path: "/customerInfoManagement",
      name: "customerInfoManagement",
      meta: {
        roles: ["JobManager.Administrator"]
      },
      component: () => import("./views/CustomerInfoManagement.vue")
    },
    {
      path: "/users",
      name: "listUsers",
      meta: {
        roles: ["JobManager.Administrator"]
      },
      component: () => import("./views/UserList.vue")
    },
    {
      path: "/user/:userid",
      name: "editUser",
      props: true,
      meta: {
        roles: ["JobManager.Administrator"]
      },
      component: () => import("./views/User.vue")
    },
    {
      path: "/user",
      name: "createUser",
      meta: {
        roles: ["JobManager.Administrator"]
      },
      component: () => import("./views/User.vue")
    },
    {
      path: "/jobListManager",
      name: "jobListManagerStatus",
      meta: {
        roles: ["JobManager.Manager"]
      },
      component: () => import("./views/JobListManager.vue")
    },
    {
      path: "/jobListArchived",
      name: "jobListManagerArchived",
      meta: {
        roles: ["JobManager.Manager"]
      },
      props: true,
      component: () => import("./views/JobListManager.vue")
    },
    {
      path: "/jobCalendar",
      name: "jobCalendar",
      props: true,
      meta: {
        roles: ["JobManager.Manager"]
      },
      component: () => import("./views/JobCalendar.vue")
    },
    {
      path: "/jobListManager/:status",
      name: "jobListManager",
      props: true,
      meta: {
        roles: ["JobManager.Manager"]
      },
      component: () => import("./views/JobListManager.vue")
    },
    {
      path: "/JobListAgent",
      name: "JobListAgent",
      meta: {
        roles: ["JobManager.Agent", "JobManager.Manager"]
      },
      component: () => import("./views/JobListAgent.vue")
    },
    {
      path: "/customers",
      name: "Customers",
      meta: {
        roles: ["JobManager.Manager"]
      },
      component: () => import("./views/CustomerList.vue")
    },
    {
      path: "/customer/:id",
      name: "EditCustomer",
      props: true,
      meta: {
        roles: ["JobManager.Manager"]
      },
      component: () => import("./components/Customer.vue")
    },
    {
      path: "/customer",
      name: "NewCustomer",
      props: true,
      meta: {
        roles: ["JobManager.Manager"]
      },
      component: () => import("./components/Customer.vue")
    },
    {
      path: "/LastLocationsMap",
      name: "LastLocationsMap",
      meta: {
        roles: ["JobManager.Manager", "JobManager.Agent"]
      },
      component: () => import("./views/LastLocationsMap.vue")
    },
    {
      path: "/Job/:jobid",
      name: "Job",
      props: true,
      meta: {
        roles: ["JobManager.Agent", "JobManager.Manager"]
      },
      component: () => import("./components/ViewJob.vue")
    },
    {
      path: "/ViewJob/:jobid/:date",
      name: "ViewJobWithDate",
      props: true,
      meta: {
        roles: ["JobManager.Agent"]
      },
      component: () => import("./components/ViewJob.vue")
    },
    {
      path: "/CreateJob",
      name: "CreateJob",
      meta: {
        roles: ["JobManager.Manager"]
      },
      component: () => import("./components/CreateJob.vue")
    },
    {
      path: "/CreateJob/:customerId",
      name: "CreateJobCustomer",
      props: true,
      meta: {
        roles: ["JobManager.Manager"]
      },
      component: () => import("./components/CreateJob.vue")
    },
    {
      path: "/EditJob/:jobid",
      name: "EditJob",
      props: true,
      meta: {
        roles: ["JobManager.Manager"]
      },
      component: () => import("./components/CreateJob.vue")
    },
    {
      path: "/Job/:jobid/Report/:reportid",
      name: "EditReport",
      props: true,
      meta: {
        roles: ["JobManager.Agent", "JobManager.Manager"]
      },
      component: () => import("./views/PlumbingReport.vue")
    },
    {
      path: "/Job/:jobid/Report",
      name: "NewReport",
      props: true,
      meta: {
        roles: ["JobManager.Agent", "JobManager.Manager"]
      },
      component: () => import("./views/PlumbingReport.vue")
    },
    {
      path: "/Job/:jobid/RiskAssessment/:assessmentid",
      name: "EditRiskAssessment",
      props: true,
      meta: {
        roles: ["JobManager.Agent", "JobManager.Manager"]
      },
      component: () => import("./views/RiskAssessment.vue")
    },
    {
      path: "/Job/:jobid/RiskAssessment",
      name: "NewRiskAssessment",
      props: true,
      meta: {
        roles: ["JobManager.Agent", "JobManager.Manager"]
      },
      component: () => import("./views/RiskAssessment.vue")
    },

    {
      path: "/auth/complete",
      name: "AuthComplete",
      meta: { anon: true },
      component: () => import("./views/authcomplete.vue")
    },
  ]
});

let brand = null;
if (process.env.VUE_APP_BRAND) {
  brand = import("@/brands/" + process.env.VUE_APP_BRAND + '/theme')
} else {
  brand = import("@/brands/default/theme")
}

if ((await brand).default.projectTab === true) {
  router.addRoutes([
    {
      path: "/projectInfoManagement",
      name: "projectInfoManagement",
      meta: {
        roles: ["JobManager.Administrator"]
      },
      component: () => import("./views/ProjectInfoManagement.vue")
    },
    {
      path: "/Projects",
      name: "Projects",
      meta: {
        roles: ["JobManager.Manager"]
      },
      component: () => import("./views/Projects.vue")
    },
    {
      path: "/Projects/:status",
      name: "ProjectsStatus",
      props: true,
      meta: {
        roles: ["JobManager.Manager"]
      },
      component: () => import("./views/Projects.vue")
    },
    {
      path: "/CreateProject",
      name: "CreateProject",
      props: true,
      meta: {
        roles: ["JobManager.Manager"]
      },
      component: () => import("./components/AddEditProject.vue")
    },
    {
      path: "/EditProject/:projectid",
      name: "EditProject",
      props: true,
      meta: {
        roles: ["JobManager.Manager"]
      },
      component: () => import("./components/AddEditProject.vue")
    },
  ]);
}

import store from "./store/index";

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta && record.meta.anon)) {
    next();
  } else {
    if (store.state.user != null) {
      if (to.matched.some(i => i.name == "home")) {
        next({ name: "JobListAgent" });
      } else if (
        to.matched.some(
          record =>
            record.meta &&
            record.meta.roles &&
            store && store.state && store.state.user && store.state.user.roles &&
            record.meta.roles.some(i => store.state.user.roles.indexOf(i) >= 0)
        )
      ) {
        next();
      } else {
        next({ name: "home" });
      }
    } else {

      next({ name: "home" });
    }
  }
});

export default router;
