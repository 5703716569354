import { apiHost } from "../config.js";
import store from "../store/index";
export const getLocation = () => {
  return new Promise(resolve => {
    navigator.geolocation.getCurrentPosition(function (position) {
      resolve(position);
    });
  });
};

export const UpdateUserLocation = async userid => {
  const position = await getLocation();

  const obj = {
    location: {
      type: "Point",
      coords: {
        accuracy: position.coords.accuracy,
        altitude: position.coords.altitude,
        altitudeAccuracy: position.coords.altitudeAccuracy,
        heading: position.coords.heading,
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        speed: position.coords.speed
      }
    },
    userid: userid
  };

  fetch(apiHost + "userLocation/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${store.state.accessToken.token}`
    },
    body: JSON.stringify(obj)
  });
};

export const getAllUserLatestLocations = async () => {
  const res = await fetch(apiHost + "userLocation/latest/all/", {
    headers: { "Authorization": `Bearer ${store.state.accessToken.token}` }
  });
  return await res.json();
};
