<template>
  <v-app class="grey lighten-4">
    <Navbar v-if="hasUser" />

    <v-main class="">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar";
import { UpdateUserLocation } from "./services/location";

var timeout = null;
export default {
  components: { Navbar },
  name: "App",
  data() {
    return {
      markers: [],
    };
  },
  computed: {
    hasUser() {
      if (!this.$store.state.user) return false;

      return true;
    }
  },
  created: async function () {
    this.$vuetify.theme.themes.light.primary = {
      base: "#7b7b84",
      darken1: "#ff0000",
      warning: "purple",
    };

    this.setUserLocationTimeout();
  },

  methods: {
    setUserLocationTimeout() {
      timeout = setTimeout(() => {
        if (this.$store.state.user != null) {
          UpdateUserLocation(this.$store.state.user._id).then((result) => {
            this.setUserLocationTimeout();
          });
        } else {
          this.setUserLocationTimeout();
        }
      }, 60000);
    },
  },
};
</script>
<style>
@import "./assets/css/ui.css";

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 28px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 28px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 28px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
  border-radius: 28px;
}
</style>
