import Vue from "vue";
import Vuex from "vuex";
import { format, parseISO, parseJSON, addHours } from "date-fns";
Vue.use(Vuex);

function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}
function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}
var s = {
  accessToken: null,
  user: null,
  date: new Date(),
  jobFilter: {
    agents: [],
    statuses: [],
    manager: null,
    type: [],
    priority: null,
    includeArchived: false,
  },
  projectFilter: {
    statuses: [],
    type: [],
    priority: null,
    includeArchived: false,
  },
  calendar: {
    filter: {
      date: format(new Date(), "yyyy-MM-dd"),
      endDate: format(new Date(), "yyyy-MM-dd"),
      type: "day",
      agents: [],
    }
  },
  jobFilterControls: {
    sortBy: "status",
    pageSize: 30,
    page: 1,
  },
  projectFilterControls: {
    sortBy: "status",
    pageSize: 30,
    page: 1,
  }
};

if (localStorage.getItem("store")) {
  //s = Object.assign(s, JSON.parse(localStorage.getItem("store")));
  s = mergeDeep(s, JSON.parse(localStorage.getItem("store")));
}


const store = new Vuex.Store({
  state: s,
  mutations: {
    accessToken(state, token, expires) {
      state.accessToken = { token, expires };
    },
    setFocusDate(state, date) {
      state.date = date;
    },
    user(state, user) {
      state.user = user;
    },
    setFilter(state, filter) {
      state.calendar.fitler = filter;
    },
    setJobFilter(state, filter) {
      state.filter = filter
    },
    setProjectFilter(state, filter) {
      state.filter = filter
    }
  }
});

store.subscribe((mutation, state) => {
  localStorage.setItem("store", JSON.stringify(store.state));
});

export default store;
