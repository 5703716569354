<template>
  <v-container v-if="userIsManager">
    <v-card>
      <v-card-title class="text-h5"><v-icon class="mr-2" color="black">mdi-folder-multiple-outline</v-icon>
        Projects</v-card-title>

      <v-card-text>

        <div class="d-flex flex-wrap flex-wrap-wrap justify-start justify-md-center flex-column flex-md-row">
          <div v-for="(status, index) in projectStatusCounts" :key="index" @click="gotoProjects(status.status.id)"
            class="d-flex flex-row flex-md-column">
            <h1 class="text-h5 d-flex justify-start justify-md-center" :style="'color:' + status.status.color">
              {{ status.count }}
            </h1>
            <v-btn @click="gotoProjects(status.status.id)" class="project-status-btn" text>{{ status.status.name }}</v-btn>
          </div>
        </div>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>
      <v-card-actions>
        <v-btn @click="gotoProjects()" text> All projects... </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { getStatusCounts } from "../services/project.js";
import { readAllItems } from '../services/systemList.js';

export default {
  name: "ProjectStatusList",
  data: () => ({
    projectStatuses: [],
    projectStatusCounts: [],
    userIsManager: false,
  }),
  methods: {
    gotoProjects(status) {
      if (status) {
          this.$router.push("/projects/" + status).catch(() => { });
      }     
     
      else {
        this.$router.push({ name: "Projects"}).catch();
      }
      

    },
  },
  async created() {
    this.userIsManager = false;
    if (this.$store.state.user)
      if (this.$store.state.user.roles.some((i) => i == "JobManager.Manager"))
        this.userIsManager = true;

    if (this.userIsManager === true) {
      var projectStatuses = await readAllItems('project_statuss');
      this.projectStatuses = projectStatuses.filter(i => i.showOnDash);
      const result = await getStatusCounts();

      this.projectStatusCounts = result.map((item) => ({
        status: this.projectStatuses.find(status => status.id === item._id.status),
        count: item.count,
      }));

      this.projectStatusCounts = this.projectStatusCounts
        .filter(item => item.status !== undefined)
        .filter((item) => item.count > 0)
        .sort((a, b) => b.count - a.count);
    }
  },
};
</script>

<style>
.project-status-btn {
  text-transform: none;
  font-weight: normal;
  text-indent: 0px;
  padding: 0;
}
</style>
