<template>
  <v-container v-if="userIsManager">
    <v-card>
      <v-card-title class="text-h5"><v-icon class="mr-2" color="black">mdi-account-hard-hat</v-icon>
        {{ jobBrandNamePlural }}</v-card-title>

      <v-card-text>

        <div class="d-flex flex-wrap flex-wrap-wrap justify-start justify-md-center flex-column flex-md-row">
          <div v-for="(status, index) in jobStatusCounts" :key="index" @click="gotoJobs(status.status.id)"
            class="d-flex flex-row flex-md-column">
            <h1 class="text-h5 d-flex justify-start justify-md-center" :style="'color:' + status.status.color">
              {{ status.count }}
            </h1>
            <v-btn @click="gotoJobs(status.status.id)" class="job-status-btn" text>{{ status.status.name }}</v-btn>
          </div>
        </div>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>
      <v-card-actions>
        <v-btn @click="gotoJobs('all')" text> All {{jobBrandNamePlural.toLowerCase()}}... </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { getStatusCounts } from "../services/job.js";
import { readAllItems } from '../services/systemList.js';
const brand = import("@/brands/" + process.env.VUE_APP_BRAND + '/theme');

export default {
  name: "JobStatusList",
  data: () => ({
    jobStatuses: [],
    jobStatusCounts: [],
    userIsManager: false,
    jobBrandNamePlural: '',
  }),
  methods: {
    gotoJobs(status) {
      if (status) this.$router.push("/jobListManager/" + status).catch(() => { });
      else this.$router.push("/jobListManager");
    },
  },
  async created() {
    let brandObj = await brand;
    this.jobBrandNamePlural = brandObj.default.jobBrandNamePlural;

    this.userIsManager = false;
    if (this.$store.state.user)
      if (this.$store.state.user.roles.some((i) => i == "JobManager.Manager"))
        this.userIsManager = true;

    if (this.userIsManager === true) {
      var jobStatuses = await readAllItems('job_statuss');
      this.jobStatuses = jobStatuses.filter(i => i.showOnDash);
      const result = await getStatusCounts();

      this.jobStatusCounts = result.map((item) => ({
        status: this.jobStatuses.find(status => status.id === item._id.status),
        count: item.count,
      }));

      this.jobStatusCounts = this.jobStatusCounts
        .filter(item => item.status !== undefined)
        .filter((item) => item.count > 0)
        .sort((a, b) => b.count - a.count);
    }
  },
};
</script>

<style>
.job-status-btn {
  text-transform: none;
  font-weight: normal;
  text-indent: 0px;
  padding: 0;
}
</style>
