import { apiHost } from "../config.js";
import store from "../store/index.js";

export const createProject = async project => {
  var projectNumberResponse = await fetch(apiHost + "projects/projectNumber", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`
    },
    body: JSON.stringify({})
  });

  var projectNumber = await projectNumberResponse.json();
  project.projectNumber = projectNumber.value;

  return await fetch(apiHost + 'projects/', {
    method: 'POST',
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`
    },
    body: JSON.stringify(project)
  });
};


export const readProjects = async (filter, sort, pageSize, page) => {
  let url = apiHost + "projects";

  if (store.state?.user?.roles.indexOf('JobManager.Administrator') != -1) {
    url += "/admin";
  }
  url += "?userId=" + store.state.user._id;

  if (filter) {
    if (filter.date) {
      url += "&date=" + filter.date;
    }
    if (filter.memberid) {
      url += "&memberid=" + filter.memberid;
    }
    if (filter.ownerid) {
      url += "&ownerid=" + filter.ownerid;
    }
    if (filter.status) {
      url += "&status=" + filter.status;
    }
    if (filter.customerId) {
      url += "&customerId=" + filter.customerId;
    }
    if (filter.type) {
      url += "&type=" + filter.type;
    }
    if (filter.priority) {
      url += "&priority=" + filter.priority;
    }
    if (filter.includeArchived) {
      url += "&includeArchived=" + filter.includeArchived;
    }
    if (filter.query) {
      url += "&query=" + filter.query;
    }
    if (filter.fromDate) {
      url += "&fromDate=" + filter.fromDate;
    }
    if (filter.toDate) {
      url += "&toDate=" + filter.toDate;
    }
    if (pageSize) {
      url += "&pageSize=" + pageSize;
    }
    if (page) {
      url += "&page=" + page;
    }
    if (sort) {
      url += "&sort=" + sort;
    }
  }

  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`,
    },
  });

  const projects = await res.json();
  return projects;
};

export const getProjectLocations = async function (filter) {
  var url = `${apiHost}projects/locations`;

  if (store.state?.user?.roles.indexOf('JobManager.Administrator') != -1) {
    url += "/admin";
  }

  url += "?userId=" + store.state.user._id;

  if (filter && filter.statuses) {
    url += "&statuses=" + filter.statuses
  }
  if (filter && filter.types) {
    url += "&types=" + filter.types
  }
  if (filter && filter.owners) {
    url += "&owners=" + filter.owners
  }
  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`,
    }
  });

  const jobs = await res.json();
  return jobs;
}

export const getProject = async projectId => {
  let userId = store.state.user._id;

  let url = `${apiHost}projects/${projectId}`;

  if (store.state?.user?.roles.indexOf('JobManager.Administrator') != -1) {
    url += "/admin";
  }

  url += `?userId=${userId}`;


  const res = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`
    }
  });

  let project = await res.json();

  if (!project) {
    return null;
  }

  const getTaskTree = async taskId => {
    const url = `${apiHost}/projects/taskTree/${taskId}`;

    const res = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${store.state.accessToken.token}`
      }
    });
    const taskTree = await res.json();
    return taskTree;
  }

  if (project.tasks) {
    let tasks = [];
    for (let i = 0; i < project.tasks.length; i++) {
      let taskTree = await getTaskTree(project.tasks[i]);
      if (taskTree) {
        tasks.push(taskTree);
      }
    }
    project.tasks = tasks;
  }


  return project;
}

export const addNotes = async (id, notes) => {
  return fetch(apiHost + `projects/${id}/notes`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`
    },
    body: JSON.stringify(notes)
  });
};

export const setNotes = async (id, notes) => {
  return fetch(apiHost + `projects/${id}/status`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`
    },
    body: JSON.stringify({ notes })
  });
};

export const updateProject = async project => {
  return await fetch(apiHost + "projects/" + project._id, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`
    },
    body: JSON.stringify(project)
  });
};

export const getStatusCounts = async () => {
  let url = apiHost + "projects/statuses";

  if (store.state?.user?.roles.indexOf('JobManager.Administrator') != -1) {
    url += "/admin";
  }

  url += "?userId=" + store.state.user._id;

  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`
    }
  });

  return await res.json();

};