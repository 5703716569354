import { apiHost } from "../config.js";
import store from "../store/index";

export const readItems = async (name) => {
  const res = await fetch(`${apiHost}${name}/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${store.state.accessToken.token}`
    }
  });

  let items = await res.json();
  items = items.filter(item => item.deleted === undefined || item.deleted === false);
  return items;
  // .sort((a, b) => {
  //   return a.name.localeCompare(b.name);
  // });
};

export const readAllItems = async (name) => {
  const res = await fetch(`${apiHost}${name}/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${store.state.accessToken.token}`
    }
  });

  const items = await res.json();
  return items;
  // .sort((a, b) => {
  //   return a.name.localeCompare(b.name);
  // });
};

export const createItem = async (name, item) => {
  return await fetch(`${apiHost}${name}/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${store.state.accessToken.token}`
    },
    body: JSON.stringify(item)
  });
};
export const updateItem = async (name, item) => {
  return await fetch(`${apiHost}${name}/${item._id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`
    },
    body: JSON.stringify(item)
  });
};

export const deleteItem = async (name, item) => {
  return await fetch(`${apiHost}${name}/${item._id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`
    },
    body: JSON.stringify(item)
  });
};
