import { apiHost } from "../config.js";
import store from "../store/index";


export const createJob = async job => {

  var jobNumberResponse = await fetch(apiHost + "jobs/jobnumber", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`
    },
    body: JSON.stringify({})
  });

  var jobNumber = await jobNumberResponse.json();
  job.jobNumber = jobNumber.value;
  return await fetch(apiHost + "jobs/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`
    },
    body: JSON.stringify(job)
  });
};

export const setScheduleComplete = async (jobId, scheduleId, sch) => {

  var url = `${apiHost}jobs/${jobId}/schedules/${scheduleId}`;

  var res = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`
    },
    body: JSON.stringify(sch)
  });

  const job = await res.json();
  return job.schedules;
}

export const getSchdules = async jobid => {
  var url = `${apiHost}jobs/${jobid}/schedules`;

  var res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`
    }
  });

  const job = await res.json();
  return job.schedules;
};

export const getJob = async jobid => {
  var url = `${apiHost}jobs/${jobid}`;

  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`
    }
  });

  const job = await res.json();
  return job;
};

export const addSchedule = async (jobid, schedule) => {
  var url = `${apiHost}jobs/${jobid}/schedules`;
  return await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`
    },
    body: JSON.stringify(schedule)
  });
};

export const deleteSchedule = async (jobid, scheduleId) => {
  var url = `${apiHost}jobs/${jobid}/schedules/${scheduleId}`;

  return await fetch(url, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`
    }
  });
};
export const readAgentJobs = async function (agent, date) {
  var url = `${apiHost}jobs/agent/${agent}?date=${date}`;
  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`,
    }
  });

  const jobs = await res.json();
  return jobs;
};
export const readAgentDates = async function (agent, date) {
  var url = `${apiHost}jobs/agent/${agent}/dates?date=${date}`;
  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`,
    }
  });

  const jobs = await res.json();
  return jobs;
};

export const getJobCalendar = async function (date, endDatte) {
  var url = `${apiHost}jobs/calendar?date=${date}`;

  if (endDatte && endDatte != date) {
    url += '&endDate=' + endDatte
  }
  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`,
    }
  });

  const jobs = await res.json();
  return jobs;
}

export const getJobLocations = async function (filter) {
  var url = `${apiHost}jobs/locations?`;
  if (filter && filter.agents) {
    url += "agents=" + filter.agents + "&"
  }
  if (filter && filter.statuses) {
    url += "statuses=" + filter.statuses + "&"
  }
  if (filter && filter.types) {
    url += "types=" + filter.types + "&"
  }
  if (filter && filter.managers) {
    url += "managers=" + filter.managers + "&"
  }
  if (filter && filter.date) {
    url += "date=" + filter.date + "&"
  }

  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`,
    }
  });

  const jobs = await res.json();
  return jobs;
}

export const readJobs = async (filter, sort, pageSize, page) => {
  var url = apiHost + "jobs?";

  if (filter) {
    if (filter.date) {
      url += "&date=" + filter.date;
    }
    if (filter.agentid) {
      url += "&agentid=" + filter.agentid;
    }
    if (filter.managerid) {
      url += "&managerid=" + filter.managerid;
    }
    if (filter.status) {
      url += "&status=" + filter.status;
    }
    if (filter.customerId) {
      url += "&customerId=" + filter.customerId;
    }
    if (filter.type) {
      url += "&type=" + filter.type;
    }
    if (filter.priority) {
      url += "&priority=" + filter.priority;
    }
    if (filter.includeArchived) {
      url += "&includeArchived=" + filter.includeArchived;
    }
    if (filter.query) {
      url += "&query=" + filter.query;
    }
    if (filter.fromDate) {
      url += "&fromDate=" + filter.fromDate;
    }
    if (filter.toDate) {
      url += "&toDate=" + filter.toDate;
    }
    if (pageSize) {
      url += "&pageSize=" + pageSize;
    }
    if (page) {
      url += "&page=" + page;
    }
    if (sort) {
      url += "&sort=" + sort;
    }
  }

  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`,
    },
  });

  const jobs = await res.json();
  return jobs;
};

export const updateJob = async job => {
  return await fetch(apiHost + "jobs/" + job._id, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`
    },
    body: JSON.stringify(job)
  });
};

export const addNotes = async (id, notes) => {
  return fetch(apiHost + `jobs/${id}/notes`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`
    },
    body: JSON.stringify(notes)
  });
};

export const setStatus = async (id, status) => {
  return fetch(apiHost + `jobs/${id}/status`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`
    },
    body: JSON.stringify({ status })
  });
};
export const setNotes = async (id, notes) => {
  return fetch(apiHost + `jobs/${id}/status`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`
    },
    body: JSON.stringify({ notes })
  });
};
export const setRiskassessmentStatus = async (id, riskAssessmentStatus) => {
  return fetch(apiHost + `jobs/${id}/status`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`
    },
    body: JSON.stringify({ riskAssessmentStatus })
  });
};

export const setArchived = async (id, archived) => {
  return fetch(apiHost + `jobs/${id}/archived`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`
    },
    body: JSON.stringify({ archived: archived })
  });
};

export const deleteJob = async id => {
  return fetch(apiHost + "jobs/" + id, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`
    }
  });
};

export const getStatusCounts = async () => {
  const res = await fetch(apiHost + "jobs/statuses", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`
    }
  });

  return await res.json();

};
