import { apiHost } from "../config.js";
import store from "../store/index";


export const createCustomer = async customer => {

  return await fetch(apiHost + "customers/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`
    },
    body: JSON.stringify(customer)
  });

};


export const getCustomer = async id => {
  var url = `${apiHost}customers/${id}`;

  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`
    }
  });

  const data = await res.json();
  return data;
};


export const readCustomers = async (filter) => {
  var url = apiHost + "customers?";

  if (filter) {
    if (filter.type) {
      url += "&type=" + filter.type;
    }
    if (filter.query) {
      url += "&query=" + filter.query;
    }
  }

  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`,
    },
  });

  const data = await res.json();
  return data;
};


export const updateCustomer = async customer => {
  return await fetch(apiHost + "customers/" + customer._id, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`
    },
    body: JSON.stringify(customer)
  });
};

export const addCustomerNotes = async (id, notes) => {
  return fetch(apiHost + `customers/${id}/notes`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`
    },
    body: JSON.stringify(notes)
  });
};

export const setNotes = async (id, notes) => {
  return fetch(apiHost + `customers/${id}/status`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.accessToken.token}`
    },
    body: JSON.stringify({ notes })
  });
};

