<template>
  <div class="home">
    <JobStatusList />
    <ProjectStatusList v-if="showProjectTab === true"/>
    <HelloWorld />
  </div>
</template>


<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import JobStatusList from "@/components/JobStatusList.vue";
import ProjectStatusList from "@/components/ProjectStatusList.vue";
const brand = import("@/brands/" + process.env.VUE_APP_BRAND + '/theme')

export default {
  name: "home",
  data() {
    return {
      showProjectTab: false,
    }
  },
  components: {
    HelloWorld,JobStatusList,ProjectStatusList
  },  
  async created() {    
    let brandObj = await brand;
    this.showProjectTab = brandObj.default.projectTab;
  }
};
</script>
