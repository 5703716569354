<template>
  <div>
    <div v-if="$store.state.user == null" class="fullscreen">
      <div style="
                    color: white;
                    position: absolute;
                    top: 30%;
                    left: 0;
                    right: 0;
                    text-align: center;
                  ">
        <component is="brand"></component>
        <div style="font-size: 20px; margin-bottom: 20px">{{ jobBrandNameSingle }} Manager</div>
        <v-btn rounded @click="SignIn"> Sign-in </v-btn>
      </div>
    </div>
    <div v-else>
      <v-container>
        <v-row>
          <v-col v-for="(link, index) in userLinks" :key="index" cols="12" md="4" lg="3">
            <v-card @click="goToLink(link)">
              <v-card-title class="text-h5"><v-icon :color="link.color" class="mr-2">{{
                link.icon
              }}</v-icon>
                {{ link.text }}
              </v-card-title>
              <div style="height: 85px">
                <v-card-text class="mt-0 pt-0">{{ link.subtitle }}
                </v-card-text>
              </div>
              <v-divider class="mx-4"></v-divider>
              <v-card-actions>
                <v-btn @click="goToLink(link)" text> Go... </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { authStart } from "../config.js";
import { links } from "../models/links";
const brand = import("@/brands/" + process.env.VUE_APP_BRAND + '/theme');

export default {
  name: "HelloWorld",
  data: () => ({
    links,
    jobBrandNameSingle: '',
    jobBrandNamePlural: '',
  }),
  components: { 'brand': () => import('@/brands/' + (process.env.VUE_APP_BRAND || "default") + "/home") },
  computed: {
    userLinks() {
      return this.links.filter(
        (i) =>
          (i.showOnDashboard == null || i.showOnDashboard !== false) &&
          i.text != "Home" &&
          i.group !== 'Management' &&
          i.text != this.jobBrandNamePlural &&
          (i.roles == null ||
            i.roles.some(
              (r) =>
                this.$store.state.user != null &&
                this.$store.state.user.roles != null &&
                this.$store.state.user.roles.indexOf(r) >= 0
            ))
      );
    },
  },
  methods: {
    SignIn() {
      window.location = authStart;
    },

    goToLink(link) {
      this.$router.push(link.route);
    },
  },
  props: {
    msg: String,
  },
  async created() {
    let brandObj = await brand;
    this.jobBrandNameSingle = brandObj.default.jobBrandNameSingle;
    this.jobBrandNamePlural = brandObj.default.jobBrandNamePlural;
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.fullscreen {
  position: fixed;
  background: #7b7b84;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
